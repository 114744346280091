import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import { HttpBackend, HttpErrorResponse} from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {Moment} from 'moment';
import { PaymentService } from '../payment.service';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
   
  },
};

@Component({
  selector: 'app-onlinepayments',
  templateUrl: './onlinepayments.component.html',
  styleUrls: ['./onlinepayments.component.css'],
  providers: [

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class OnlinepaymentsComponent implements OnInit {

  date = new FormControl(moment());

  netamount=0;
isDownloadDisabled:boolean = true;
  memberName :string;
  members :any=[];
  month:any;
  year:any;
  showMe:boolean=false;
  secondHalf:Array<any> = [];
  firstHalf:Array<any> = [];
  monthYear : Array<any>=[];
  transactionGet:Array<any>=[];
  allUser:any=[];
  isLoading:boolean=false;
  allUsersArray=[];
  alluser:any=[]
 
  constructor(private common:PaymentService,private _snackBar:MatSnackBar) {}

  ngOnInit() {

    
    this.monthYear=[]

    this.monthYear.push(new Date().getMonth()+1)
    this.monthYear.push(new Date().getFullYear())
  

    this.transactionApiCalling();
   
  }

  hello(){
    
    var half1 = Math.ceil(
      this.allUsersArray.length / 2);
     let data=JSON.parse(JSON.stringify(this.allUsersArray));
     
    this.firstHalf =  data.splice(0, half1)
    this.secondHalf =  data.splice(-half1)
      
}
downloadFile(){
let a = document.createElement('a');
 a.setAttribute('href','https://liveapi-hariharakshethra.softexer.com/api/admin/downloadMonthTransaction?monthDate='+this.monthYear);
 a.click();
}
chosenYearHandler(normalizedYear: Moment) {
  const ctrlValue = this.date.value;

  ctrlValue.year(normalizedYear.year());
  this.date.setValue(ctrlValue);


 this.year=normalizedYear.year()
 
}

chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  const ctrlValue = this.date.value;
  ctrlValue.month(normalizedMonth.month());
  this.date.setValue(ctrlValue);
  datepicker.close();

  this.month=normalizedMonth.month();


  this.monthYear=[]

  this.monthYear.push(this.month+1)
  this.monthYear.push(this.year)


 this.transactionApiCalling()
  
}

transactionApiCalling(){
this.isDownloadDisabled = true
  this.isLoading=true;
  let wow={
     
    "monthDate": `[${this.monthYear}]`,

  }


  console.log("asdss",wow)
  



  this.netamount=0
 this.common.transactionApi(wow).subscribe((login)=>
 {
  console.log("login res",login);
   if(login.response === 3){
    this.isLoading=false;
    this.isDownloadDisabled = false;
    this.openSnackBar(login.message, "");
    this.netamount=0
    this.allUser = login.monthTransactions;
    this.allUsersArray=login.monthTransactions;
   // this.allUsersArray = login.monthTransactions;
    var newarray = this.allUsersArray.filter(function (el){
      return el.paymentType=="offline"
    })
    this.allUsersArray=newarray;

    for(let i=0; i<this.allUsersArray.length;i++){
      this.netamount+=this.allUsersArray[i].Amount
   }
    
    // this.hello();    
  }else{
this.isDownloadDisabled = true;
    this.isLoading=false;
    this.allUsersArray=login.monthTransactions;
    this.openSnackBar1(login.message, "");

  }



 },(err:HttpErrorResponse)=>{
   this.isDownloadDisabled = true;
      if(err.error instanceof Error){
        console.log("Client Error ::",err.error)
      }else{
        console.log("Server Error ::",err.error) 
      }
    })
  
}


openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {
    panelClass: ['theme-snackbar'],
    duration: 2000,
    verticalPosition: "bottom", // 'top' | 'bottom'
    horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
  });
}

openSnackBar1(message: string, action: string) {
  this._snackBar.open(message, action, {
    panelClass: ['red-snackbar'],
    duration: 2000,
    verticalPosition: "bottom", // 'top' | 'bottom'
    horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
  });
}


}
