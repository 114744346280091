import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '../payment.service';
import { HttpBackend, HttpErrorResponse} from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  urlLink= "https://liveapi-hariharakshethra.softexer.com";
  imageUrl : any ="../../assets/addimage.png";
  fileToUpload : File = null;
  imgUrls:Array<any> = [];
  myForm:FormGroup;
  alluser:any=[];
  isLoading: boolean = false;
  filesArray:Array<any>=[];
  productsSubcribe: any;
  serverResponse: any;
  notification: any=[];
  sub: any;
  mobileNumber: any;
  constructor(private fb:FormBuilder,private common:PaymentService,private _snackBar:MatSnackBar, private activatedRoute: ActivatedRoute) { 
    this.common.isUserLoggedIn.subscribe(value => {
      console.log("Boolean", value)
      // this.getnotifications();

    });
  }
  

  ngOnInit() {
   
    this.myForm=this.fb.group({
      msgTitle:["",Validators.required],
      messageBody:["",Validators.required]
    })
    
    // this.sub = this.activatedRoute.params.subscribe(params => {
    //   this.mobileNumber = params['mobileNumber'];
    //   this.getnotifications();
     
    // })
  }

  // getnotifications() {
  //   let obj={
  //     mobileNumber:this.mobileNumber
  //   }
  //   this.productsSubcribe = this.common.getnotification(obj).subscribe((posRes) => {
  //     this.serverResponse = posRes;
  //     console.log(this.serverResponse)
  //     if (this.serverResponse.response == 3) {
  //       this.notification = this.serverResponse.memberInbox[0].notifyInbox;
  //       console.log(this.notification )
  //     }
  //   })
  // }

  handleFileInput(event :any){
    if(this.imgUrls.length < 5){
      let file =event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onload = () =>{
      this.imageUrl = reader.result;
      this.imgUrls.push(reader.result);
      this.filesArray.push(file);
      console.log("hyt" ,this.imgUrls);
    }
    }else{
     this.openSnackBar("only 5 images allowed","");
    }
  }

 


 
deleteImage(index){
  this.imgUrls.splice(index,1);
  this.filesArray.splice(index,1);
}

notificationSubmit(){
  if(this.myForm.valid){
    this.isLoading = true; 
     let formData = new FormData();
     formData.append("notificationData",JSON.stringify(this.myForm.value));
     if(this.filesArray.length >= 1){
       for(let i:number = 0; i< this.filesArray.length; i++){
        if(i == 0){
          formData.append("image",this.filesArray[i]);
        }else{
          formData.append("image"+i, this.filesArray[i])
        }
       }
     }
    this.common.notification(formData).subscribe((login)=>{
      console.log("login res",login);
      if(login.response === 3){
        this.isLoading = false; 
        this.openSnackBar(login.message, "");
        this.myForm.reset();
        this.imgUrls=[];
        this.filesArray=[];
      }else{
        this.isLoading = false; 
      }   
     
    }
     ,(err:HttpErrorResponse)=>{
      this.isLoading = false; 
      if(err.error instanceof Error){
        console.log("Client Error ::",err.error)
      }else{
        console.log("Server Error ::",err.error) 
      }
    })
  
  }else{
    alert("Form is not valid");
  }
}


openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {
    panelClass: ['theme-snackbar'],
    duration: 5000,
    verticalPosition: "bottom", // 'top' | 'bottom'
    horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
  });
}

//Mat Snack Bar
openSnackBar1(message: string, action: string) {
  this._snackBar.open(message, action, {
    panelClass: ['red-snackbar'],
    duration: 5000,
    verticalPosition: "bottom", // 'top' | 'bottom'
    horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
  });
}

cancelSubmit(){
  this.myForm.reset();
  this.imgUrls=[];
}
}