import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators,AbstractControl } from '@angular/forms';
import { PaymentService } from 'src/app/payment.service';
import { HttpBackend, HttpErrorResponse} from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { commonService } from 'src/app/common.service';
import { MatSnackBar } from '@angular/material';
import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  sub: any;
  id: string;
  reset:any=[];
  queryObj: any = {};
  signObj: any;
  resetForm:FormGroup;
  forgotForm:FormGroup;
  loginForm:FormGroup;
  successResponse: string;
  failureResponse: string;
  alluser:any=[];
  closeResult: string;
  forgot:any=[];
  isPassword:boolean=true;
  password1:string="password";
  isLoading: boolean = false;
  isPasswordOne:boolean=true;
  password2:string="password";
  isPassword2:boolean=true;
  password3:string="password";
  isPasswordTh:boolean=true;
  obj:any;
  urlLink: string;
  mobileNumber:any;
  password:string="password";
  Show: boolean = false;
 



  viewSuccessContent1: string = "Password Reset Successful!";
  viewFailureContent1: string = "Password Reset Failed!";
  viewSuccessContent2: string = "Your password has been changes successfully.";
  viewFailureContent2: string = "Your password has not been changed.";
  @ViewChild('viewSuccessContent', { static: true }) modalSuccessExample: ElementRef<any>;
  @ViewChild('viewFailureContent', { static: true }) modalFailureExample: ElementRef<any>;
  @ViewChild('reset', { static: true }) resetModal: ElementRef<any>;
 

  constructor(private fb:FormBuilder , private common:PaymentService,private modalService: NgbModal,
  private router:Router,public commonService:commonService, private activatedRoute: ActivatedRoute,private _snackBar:MatSnackBar) { }

  ngOnInit() {
      this.activatedRoute.queryParamMap.subscribe(route=>{
      this.obj={ ...route }
      })
  
      console.log("resp", this.obj);
      this.loginForm=this.fb.group({
      mobileNumber:["",Validators.required],
      password:["",Validators.required],
      })

     this.forgotForm=this.fb.group({
      mobileNumber:["",Validators.required],
      })

      
 
      this.resetForm = this.fb.group({
      "mobileNumber": [""],
      "password": ['', [Validators.required, Validators.minLength(3)]],
      "newPassword": ['', Validators.required],
        }
      , {
        validators: this.checkPasswords
      }
        );
       
      // var signInRes = localStorage.getItem("SignInRes");
      // if (signInRes) {
      // this.signObj = JSON.parse(signInRes);
      //  }
      // this.id = this.queryObj.params && this.queryObj.params.mobileNumber ? this.queryObj.params.mobileNumber : "";
      //  console.log("value of mobileNumber : ", this.id);
      //http://spectrocare.vedaslabs.com/#/adminresetpassword?userID=petcare
      // this.urlLink = "https://hariharakshethra.com/#/adminresetpassword?mobileNumber=" + this.id;
      // this.forgotForm = this.fb.group({
      //   "mobileNumber": [""],
     //   //"password": ['', [Validators.required, Validators.minLength(3)]],
     //   //"newPassword": ['', Validators.required],
     // }
     //   , {
     //     validators: this.checkPasswords
     //   }
     // )
       this.onLoad()
  }

 
  checkPasswords(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('newPassword').value) {
      return { invalid: true };
    }
  }


 onLoad(){
if(this.obj && this.obj.params && this.obj.params.isforgotPass){
  console.log("error")
  console.log(this.obj.params.emailid)
  this.resetForm.patchValue({
    mobileNumber: this.obj.params.emailid
  })
  this.modalService.open(this.resetModal)
}
 }


  //Mat Snack Bar
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['theme-snackbar'],
      duration: 5000,
      verticalPosition: "bottom", // 'top' | 'bottom'
      horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  //Mat Snack Bar
  openSnackBar1(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['red-snackbar'],
      duration: 5000,
      verticalPosition: "bottom", // 'top' | 'bottom'
      horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
    });
  }


  // openSnackBar2(message: string, action: string) {
  //   this._snackBar.open(message, action, {
  //     panelClass: ['red-snackbar'],
  //     duration: 5000,
  //     verticalPosition: "bottom", // 'top' | 'bottom'
  //     horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
  //   });
  // }

  viewSuccessMethod(viewSuccessContent) {
    this.modalService.open(viewSuccessContent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "sm" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  viewFailureMethod(viewFailureContent) {
    this.modalService.open(viewFailureContent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "sm" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    this.loginForm.reset();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }


  onSubmit():void{
    if(this.loginForm.valid){
      console.log(this.loginForm.value)
    }else{
      alert("enter all required fields")
    }

    if(this.forgotForm.valid){
      console.log(this.forgotForm.value)
    }else{
      alert("enter all required fields")
    }
  }

 

  loginSubmit(){
    
    
   
    if(this.loginForm.valid){
      this.isLoading=true;
      console.log(this.loginForm.value);
      let wow={
        "mobileNumber": this.loginForm.value.mobileNumber.trim(),
      "password": this.loginForm.value.password.trim(),
     
      }
     //  this.alluser.push(wow);
    
      this.common.loginUser(wow).subscribe((login)=>
      {
       

        console.log("login res",login);

      
        if(login.response === 3){
          this.isLoading = false;
          this.openSnackBar(login.message, "");
          
          localStorage.setItem("login", "true");
       
           localStorage.setItem("mobileNum",this.mobileNumber);
          this.commonService.setLogin(true);

  
          this.router.navigateByUrl('/admin/notifications');
          this.modalService.dismissAll();
          this.loginForm.reset();
    
        }else{

          this.isLoading = false;
          this.loginForm.reset();

          
        
        this.openSnackBar1(login.message,"");
      
    
        }   
       
      }
       ,(err:HttpErrorResponse)=>{
        if(err.error instanceof Error){
          console.log("Client Error ::",err.error)
        }else{
          console.log("Server Error ::",err.error) 
        }
      })
    
    }else{
     // alert("Form is not valid");
    }
  }

    forgotSubmit(){
      if(this.forgotForm.valid){

      console.log(this.forgotForm.value);
      let hello={
        "mobileNumber": this.forgotForm.value.mobileNumber.trim(),
     
       }
       localStorage.setItem("SignInRes",hello.toString());
      // this.forgot.push(hello);
    
      this.common.forgotUser(hello).subscribe((response)=>
      {
        if(response.response === 3){
        this.isLoading=false;
       
        this.openSnackBar1(response.message, "");
        
        this.modalService.dismissAll();
    

         }else{
          this.openSnackBar1(response.message, "");
         }
          console.log("res",response);
      }
       ,(err:HttpErrorResponse)=>{
        if(err.error instanceof Error){
          console.log("Client Error ::",err.error)
        }else{
          console.log("Server Error ::",err.error) 
        }
      })
    
    }else{
    //  alert("Form is not valid");
    }
  
  }

  resetSubmit(){
      if (this.resetForm.valid) {
        this.isLoading = true;
        //this.resetForm.get('mobileNumber').setValue(this.id)
        //console.log(this.signObj.access_token);
        //console.log("adminResetPwd Req Data: ", this.resetForm.value);
  
        let payLoad = this.resetForm.value;
        delete payLoad.newPassword;
        console.log("req data : ", payLoad);
  
        this.common.resetUser(payLoad).subscribe(
          (result) => {
            console.log("admin reset Pwd Response : ", result);
  
            if (result.response === 3) {
             
              this.isLoading = false;
              this.successResponse = result.message;
              this.modalService.open(this.modalSuccessExample)
            //   localStorage.setItem("login", "true");
            //   localStorage.setItem("mobileNum",this.resetForm.get('mobileNumber').value());
            //  this.commonService.setLogin(true);
   
     
            //  this.router.navigateByUrl('/admin/notifications');
            //  this.modalService.dismissAll();
            //  this.loginForm.reset();
            }
            else {
              this.isLoading = false;
              this.failureResponse = result.message;
              this.modalService.open(this.modalFailureExample)
              //this.openSnackBar(changePwdRes.message, "");
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              //this.loading = false;
              this.isLoading = false;
              console.log("Client Side Error", err);
  
            } 
          }
        );
      } else {
        alert("User ID not given")
      }
    }
     
  openTerms(content) {
 //   this.isButton = false;
    this.modalService.open(content, {centered:true,   size: 'lg', backdrop:"static" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openTerm(forgot) {
    //   this.isButton = false;
       this.modalService.open(forgot, { ariaLabelledBy: 'modal-basic-title', centered:true, size: 'lg', backdrop:"static" }).result.then((result) => {
         this.closeResult = `Closed with: ${result}`;
       }, (reason) => {
         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       });
     }

     openTurn(reset) {
       
      //   this.isButton = false;
         this.modalService.open(reset, { ariaLabelledBy: 'modal-basic-title',centered:true, size: 'lg', backdrop:"static" }).result.then((result) => {
           this.closeResult = `Closed with: ${result}`;
         }, (reason) => {
           this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
         });
       }
  
   backToSignIn(content){
    this.modalService.dismissAll();
   this.openTerms(content);
   this.forgotForm.reset();
 
    
  }

  showPassword() {
    if (this.isPassword === true) {
      this.password1 = "text";
      this.isPassword = false;
    } else {
      this.password1 = "password"
      this.isPassword = true;
    }
  }

  showPassword1() {
    if (this.isPasswordOne === true) {
      this.password2 = "text";
      this.isPasswordOne = false;
    } else {
      this.password2 = "password"
      this.isPasswordOne = true;
    }
  }

    showPassword3() {
    if (this.isPasswordTh === true) {
      this.password3 = "text";
      this.isPasswordTh = false;
    } else {
      this.password3 = "password"
      this.isPasswordTh = true;
    }
  }
  
 returnlogin(){
   
}

signin(){

}
 
  

}

 
 





 



 