import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../payment.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from "@angular/router";


import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {
  urlLink = "https://liveapi-hariharakshethra.softexer.com";
  MemeberList: [
    { "MemberName": any },
    { "MemberId": any },
  ]
  memberName: string;
  isLoading: boolean = false;
  members: any = [];
  showMe: boolean = false;
  allUser: any = [];
  secondHalf: Array<any> = [];
  firstHalf: Array<any> = [];
  allUsersArray = [];

  constructor(private common: PaymentService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router) {

  }

  ngOnInit() {
    this.getMembers();
  }



  hello() {
    var half1 = Math.ceil(
      this.allUsersArray.length / 2);
    let data = JSON.parse(JSON.stringify(this.allUsersArray));
    this.firstHalf = data.splice(0, half1)
    this.secondHalf = data.splice(-half1)
  }


  toggleTag(i: string) {
    if (this.firstHalf[i].MemberList.length) {
      this.showMe = !this.showMe
      console.log(document.getElementById("show" + i).style.display)
      if (document.getElementById("show" + i).style.display == "block") {
        document.getElementById("show" + i).style.display = "none"
      } else {
        document.getElementById("show" + i).style.display = "block"
      }
    } else {
      // this.openSnackBar("No Members","")
    }

  }
  toggleSecondTag(i: number) {
    if (this.secondHalf[i].MemberList.length) {
      this.showMe = !this.showMe;
      console.log(document.getElementById(i + 1 + "show1").style.display)
      debugger
      if (document.getElementById(i + 1 + "show1").style.display == "block") {
        document.getElementById(i + 1 + "show1").style.display = "none"
      } else {
        document.getElementById(i + 1 + "show1").style.display = "block"
      }
    } else {

      //this.openSnackBar("No Members","")

    }



  }



  search() {

    console.log("king", this.allUser);
    this.allUsersArray = this.allUser.filter((res: { MemberName: string; }) => {
      return res.MemberName.toLocaleLowerCase().match(this.memberName.toLocaleLowerCase());
    })

    console.log("event", this.allUsersArray);
    this.hello();
  }


  getMembers() {

    this.isLoading = true;

    this.common.membersApi().subscribe((resp: { response: number; result: any[]; }) => {

      console.log("erroer", resp);

      if (resp.response === 3) {
        this.isLoading = false;
        this.allUser = resp.result;
        this.allUsersArray = resp.result;
        this.hello();
      } else {

        this.isLoading = false;

      }



    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['red-snackbar'],
      duration: 1000,
      verticalPosition: "bottom", // 'top' | 'bottom'
      horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(MembersComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getValenterDetails(user: { MemberId: string; }) {
    console.log(user);
    this.router.navigate(["/admin/valenteer", btoa(user.MemberId)], {
      queryParams: { isEncpt: "y" },
    })
  }
}
