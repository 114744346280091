import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Sree Kasi Visweswara Swamy Alaya Trust';
  show:boolean=false;
 // showHeader=true;
  constructor(private router: Router,private activateRoute:ActivatedRoute){
   
   
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  
  }

  ngOnInit(){
    console.log("route",this.activateRoute);


    console.log("simple",this.router);
  }

  // isValid(){
    
  //   let routeName=this.router.url.split("?")[0]
  //   console.log("head",routeName);
  //   if(["/adminresetpassword"].includes(routeName)){
     
  //     this.showHeader=false;
  //     } 
  //     this.showHeader=true;
  // }


  showHeader():Boolean {
    if((this.router.url != '/') &&
    (this.router.url.indexOf('adminresetpassword') == -1) 
)



  
  {
    return true;
  }
  return false;
  
}

showFooter():Boolean {
  if((this.router.url != '/') &&
  (this.router.url.indexOf('adminresetpassword') == -1) 
)




{
  return true;
}
return false;

}
}