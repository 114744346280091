import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentService } from '../payment.service';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-valenteer',
  templateUrl: './valenteer.component.html',
  styleUrls: ['./valenteer.component.css']
})
export class ValenteerComponent implements OnInit {
  isLoading:boolean=false;
  allValenterArray =[];
  allValenter: any = [];
  routeSub: any;
  id: any;
  queryStr: any;
  phoneNumber : string;
  valenterdonations : Array<any> =[];
  professtionalbasedDonation : Array<any> =[];
  ProjectBasedDonation : Array<any> =[];
  VoluntryContribution : Array<any> =[];
  item_materialDonation : Array<any> =[];

  constructor(private apiService:PaymentService,private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryStr = { ...params };
    });
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      //log the entire params object
      this.id = params["id"];
    });

    if (this.queryStr.params.isEncpt == "y") {
      this.id = atob(this.id);
      this.getValenterData()
      //console.log(this.id);
    } else {
      console.log(this.id);
    }
    //this.getValenterData();
  }

  getValenterData(){
    this.isLoading=true;
    let paylod ={
      "PhoneNumber" :""+this.id
    }
    this.apiService.getValenteers(paylod).subscribe((resp)=>{
     // console.log("erroer",resp);
     if(resp.response === 3){
       this.isLoading=false;
       this.allValenter = resp.Info;
       this.allValenterArray=resp.Info;
       this.allValenterArray.filter(info =>{
       
       if(info.professtionalbasedDonation){
        let obj1 = info.professtionalbasedDonation;
        obj1["DonationID"] = info.DonationID;
        this.professtionalbasedDonation.push(obj1);
       }
      
       if(info.ProjectBasedDonation){
        let obj1 = info.ProjectBasedDonation;
        obj1["DonationID"] = info.DonationID;
        this.ProjectBasedDonation.push(obj1);
       }
        
        if(info.VoluntryContribution){
          let obj1 = info.VoluntryContribution;
          obj1["DonationID"] = info.DonationID;
          this.VoluntryContribution.push(info.VoluntryContribution);
        }
      
        if(info.item_materialDonation){
          let obj1 = info.item_materialDonation;
          obj1["DonationID"] = info.DonationID;
          this.item_materialDonation.push(info.item_materialDonation);
        }
       
       })
     console.log(this.professtionalbasedDonation);
     console.log(this.ProjectBasedDonation);
     console.log(this.VoluntryContribution);
     console.log(this.item_materialDonation);
     }else{
       this.isLoading=false;
     }
   })
 }

}
