import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { SugamComponent } from 'src/app/sugam/sugam.component';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SugamComponent>,) { }

  ngOnInit() {
  }
  onSubmit(){
    this.dialogRef.close(true)
  }
  ondelete(){
    this.dialogRef.close(false)
  }
}
