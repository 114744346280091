import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public iscustomerLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(private http:HttpClient) { }
  donate(data):Observable<any>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin":"3.227.139.47:3000",
        "Access-Control-Allow-Headers": "Accept , x-requested-with, Content-Type",
        "Access-Control-Allow-Credentials": "true"
      }),
      withCredentials:false,
      body: {
        CUST_ID:data.CUST_ID,
        TXN_AMOUNT : data.TXN_AMOUNT,
        CHANNEL_ID: data.CHANNEL_ID,
        ORDER_ID:data.ORDER_ID
      }
    }

    
   
return this.http.post("https://SreeKasiVisweswaraSwamyAlayaTrust/PaytmKit/pgRedirect.php",data)
  }
  transactionStatus(id):Observable<any>{
    return this.http.post("https://SreeKasiVisweswaraSwamyAlayaTrust/paymentprocess/TxnStatus.php",id)
  }
  sendDonarDetails(data):Observable<any>{
    return this.http.post("https://SreeKasiVisweswaraSwamyAlayaTrust/paymentprocess/paymentDone.php",data,{responseType: 'json'})
  }

//   loginUser(loginObjData):Observable<any>{
// //return this.http.post( "https://jsonplaceholder.typicode.com/posts",user)
//   return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/Login",loginObjData) 
// }
signup(data):any{
  return this.http.post("https://liveapi-hariharakshethra.softexer.com/api/apna/register",data)
}

loginskvt(loginObjData):any{
    return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/apna/login",loginObjData) 
  }

loginUser(loginObjData):any{
 
  //return this.http.post( "https://jsonplaceholder.typicode.com/posts",user)
    return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/Login",loginObjData) 
  }

  forgotUser(forgotData):any{
   // return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/forgetpassword",forgotData)
   return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/forgetpassword",forgotData)
  }

 resetUser(resetData):any{
  
   return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/resetpassword",resetData)
 }

 notification(send):any{
   //return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/sendNotification",send)
   return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/sendNotification",send)
 
  }

 membersApi():any{
   //return this.http.get("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/getMembers")
   return this.http.get(" https://liveapi-hariharakshethra.softexer.com/api/admin/getMembers")
  }

 transactionApi(paid):any{
   //return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/getMonthTransactions",paid)
   return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/getMonthTransactions",paid)
 }

 signoutApi(logOut):any{
   //return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/logout",logOut)
   return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/logout",logOut)
 }

 addImages(gallery):any{
  //  return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/uploadGalaryImgFile",gallery)
  return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/uploadGalaryImgFile",gallery)
 }

 offline(offl):any{
   //return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/getOfflineTransactions",offl)
   return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/getOfflineTransactions",offl)

 }

 approveApi(offline):any{
   //return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/aproveOfflineTransactions",offline)
   return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/aproveOfflineTransactions",offline)

  }

 downloadApi():any{
   //return this.http.get("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/downloadMonthTransaction/")
   return this.http.get(" https://liveapi-hariharakshethra.softexer.com/api/admin/downloadMonthTransaction/")

  }

 uploadGallery(gallery):any{
  // return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/uploadGalaryImgFile",gallery)
  return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/uploadGalaryImgFile",gallery)

  }

 getGallery():any{
  // return this.http.get("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/getGalary")
  return this.http.get(" https://liveapi-hariharakshethra.softexer.com/api/admin/getGalary")

  }
  getValenteers(data) : any{
    return this.http.post("https://liveapi-hariharakshethra.softexer.com/api/Donations/fetch",data);
  }

 deleteGallery(delGallery):any{
  // return this.http.post("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/deleteImgGalary",delGallery)
  return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/deleteImgGalary",delGallery)

  }

 getDocuments():any{
  // return this.http.get("https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/getdocs")
  return this.http.get(" https://liveapi-hariharakshethra.softexer.com/api/admin/getdocs")

}

 deleteDocuments(dele):any{
   //return this.http.post(" https://api.Sree Kasi Visweswara Swamy Alaya Trust .org /api/admin/deleteDocument",dele)
   return this.http.post("  https://liveapi-hariharakshethra.softexer.com/api/admin/deleteDocument",dele)

  }

  onlinetransactionApi(data):any{
    return this.http.post("  https://liveapi-hariharakshethra.softexer.com/api/payments/onlinepayment",data)
  }

//  deleteDocumentsMultiple(delet):any{
//  // return this.http.post("http://54.167.167.144:3000/api/admin/uploadmultiple",delet)
//  return this.http.post(" https://liveapi-hariharakshethra.softexer.com/api/admin/uploadmultiple",delet)

// }
sugam(data):any{
  return this.http.post("  https://liveapi-hariharakshethra.softexer.com/api/puja/insert",data)
}
getProduct():any{
  return this.http.get("https://liveapi-hariharakshethra.softexer.com/api/puja/fetch")
  // special gift for vinod anna and mohan anna
  return this.http.get(" https://liveapi-hariharakshethra.softexer.com/api/puja/fetchweb")
}
deletepuja(data):any{
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    body: {
      pujaName: data.pujaName
    }
  }


  return this.http.delete("https://liveapi-hariharakshethra.softexer.com/api/puja/delete",options)
  
}
updatepuja(data):any{
 return this.http.put("https://liveapi-hariharakshethra.softexer.com/api/puja/update",data)
  
}
getnotification(data):any{
  return this.http.post("https://liveapi-hariharakshethra.softexer.com/api/notification/fetchNotification",data)
}
getBilldesktoken(data):any{
  return this.http.post("https://liveapi-hariharakshethra.softexer.com/api/payments/getPaytmTxnToken",data)
}

}
