import { Component, OnChanges, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogConfig, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { commonService } from 'src/app/common.service';
import { ContactUsComponent } from '../contact-us/contact-us.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{
  public isLogin=false;
 
  constructor(private dialog: MatDialog,public commonService:commonService,private router:Router) { 
    this.commonService.isUserLoggedIn.subscribe(val => {
      if(val == true){
      this.isLogin = true;
      //this.issignIn = true;
      }
    })
  }

  ngOnInit() {
let  isLogin = localStorage.getItem("login")
console.log(isLogin);

    if(isLogin=="true"){
     this.commonService.setLogin(true);
      this.isLogin=true;
    }

    
  }

  openContactUs(){
    let dailogRef=this.dialog.open(ContactUsComponent,{ 
      panelClass: 'col-sm-6',
      }) 
      }
  open(r){

  }
  ngOnChanges(){
    
  }

  logout() {
   
    this.commonService.isUserLoggedIn.next(false);
    this.isLogin=false;
   
  }

  changeAdmin(){
    this.router.navigateByUrl('/admin/notifications');
  }


}
