import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donation-refund',
  templateUrl: './donation-refund.component.html',
  styleUrls: ['./donation-refund.component.css']
})
export class DonationRefundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
