import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { commonService } from '../common.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  closeResult: string;
  isSideNavOpen:boolean=false;
  allUser:any=[];

  constructor(public commonService:commonService,public router:Router,private modalService: NgbModal,private common:PaymentService) { }

  ngOnInit() {

    if(!localStorage.getItem("login")){
      this.router.navigateByUrl('/');

      
    
     }

     this.getMembers();

  }


  openSideNav(){
    this.isSideNavOpen =  !this.isSideNavOpen
  }
  logOut(){

    let wow={
      "mobileNumber":localStorage.getItem("mobileNum")
    }

    this.common.signoutApi(wow).subscribe((out)=>
    {
      console.log("logout",out)
    })

    this.commonService.setLogin(false);
    this.router.navigateByUrl("/");

    localStorage.clear()
    this.modalService.dismissAll();

  }

  openBar(signOut) {
       
    //   this.isButton = false;
       this.modalService.open(signOut, { ariaLabelledBy: 'modal-basic-title',centered:true, size: 'sm', backdrop:"static" }).result.then((result) => {
         this.closeResult = `Closed with: ${result}`;
       }, (reason) => {
         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       });
     }

     private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }

    noSign(){
      this.modalService.dismissAll();
    }

    getMembers(){

  
 
      this.common.membersApi().subscribe((resp)=>{
    
        console.log("erroer",resp);
    
        if(resp.response === 3){
       
          this.allUser = resp.result;
        
        }else{
    
        }
    
        
    
      })
    }


     
}
