import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ProjectsComponent } from './components/seva/projects.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { DonateComponent } from './components/donate/donate.component';
import { JoinUsComponent } from './components/join-us/join-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { RefundPolicyComponent } from './components/refund-policy/refund-policy.component';
import { PaymentComponent } from './components/payment/payment.component';
import { QrPaymentComponent } from './components/qr-payment/qr-payment.component';
import { DonationRefundComponent } from './components/donation-refund/donation-refund.component';
 
import { AdminComponent } from './admin/admin.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { MembersComponent } from './members/members.component';
 import { ApproveComponent } from './approve/approve.component';
import { AdminResetPasswordComponent } from './admin-reset-password/admin-reset-password.component';
import { TransactionsComponent } from './transactions/transactions.component';
 import { OnlinepaymentsComponent } from './onlinepayments/onlinepayments.component';
 import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SugamComponent } from './sugam/sugam.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ReturnurlComponent } from './components/returnurl/returnurl.component';
import { BilldeskpayComponent } from './components/billdeskpay/billdeskpay.component';
import { ValenteerComponent  } from './valenteer/valenteer.component';
const routes: Routes = [
  
  {path:'home',component:HomeComponent},
  {path:"aboutUs",component:AboutUsComponent},
  {path:"contactUs",component:ContactUsComponent},
  {path:"projects",component:ProjectsComponent},
  {path:"gallery",component:GalleryComponent},
  {path:"donate",component:DonateComponent},
  {path:"documents",component:JoinUsComponent},
  {path:"termsandconditions",component:TermsAndConditionsComponent},
  {path:"payment",component:PaymentComponent},
  {path:"privacypolicy",component:RefundPolicyComponent},
  {path:"disclaimer",component:DisclaimerComponent},
  {path:"qrScanPay",component:QrPaymentComponent},
  {path:'refundpolicy',component:DonationRefundComponent},
  {path:'adminresetpassword',component:AdminResetPasswordComponent},
  {path:'paymentstatus',component:ReturnurlComponent},
  {path:'login',component:LoginComponent},
  {path:'signup',component:SignupComponent},
  {path:'dialog',component:DialogComponent},
  {path:'paydonate',component:BilldeskpayComponent},
  {path:"admin",component:AdminComponent,children:[
    {path:"notifications",component:NotificationsComponent},
    {path:"transactions",component:TransactionsComponent},
    {path:"members",component:MembersComponent},
     {path:"approve",component:ApproveComponent},
     {path:"offline",component:OnlinepaymentsComponent},
     {path:"sugam",component:SugamComponent},
     {path:"valenteer/:id",component:ValenteerComponent},
  ]},
  
  
  {path:'',redirectTo:'home',pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
