import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-admin-reset-password',
  templateUrl: './admin-reset-password.component.html',
  styleUrls: ['./admin-reset-password.component.css']
})
export class AdminResetPasswordComponent implements OnInit {
  sub: any;
  id: string;
  isPasswordOne: boolean = true;
  isPasswordTwo: boolean = true;
  password1: string = "password";
  password2: string = "password";
  signObj: any;
  closeResult: string;
  isLoading: boolean = false;
  changePatientPasswordForm: FormGroup;
  successResponse: string;
  failureResponse: string;
  queryObj: any = {};
  urlLink: string;
  viewSuccessContent1: string = "Password Reset Successful!";
  viewFailureContent1: string = "Password Reset Failed!";
  viewSuccessContent2: string = "Your password has been changes successfully.";
  viewFailureContent2: string = "Your password has not been changed.";
  @ViewChild('viewSuccessContent', { static: true }) modalSuccessExample: ElementRef<any>;
  @ViewChild('viewFailureContent', { static: true }) modalFailureExample: ElementRef<any>;
  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder,
    private _snackBar: MatSnackBar, private common:PaymentService,
    private modalService: NgbModal,private router:Router) { }

  ngOnInit() {
    this.sub = this.activatedRoute.queryParamMap.subscribe(params => {
      this.queryObj = { ...params.keys, ...params }
    })
    var signInRes = localStorage.getItem("SignInRes");
    if (signInRes) {
      this.signObj = JSON.parse(signInRes);
    }
    this.id = this.queryObj.params && this.queryObj.params.mobileNumber ? this.queryObj.params.mobileNumber : "";
    console.log("value of mobileNumber : ", this.id);
    //http://spectrocare.vedaslabs.com/#/adminresetpassword?userID=petcare
    this.urlLink = "https://hariharakshethra.com/#/adminresetpassword?mobileNumber=" + this.id;
    this.changePatientPasswordForm = this.fb.group({
      "mobileNumber": [""],
      "password": ['', [Validators.required, Validators.minLength(3)]],
      "newPassword": ['', Validators.required],
    }
      , {
        validators: this.checkPasswords
      }
    )
  }

  checkPasswords(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('newPassword').value) {
      return { invalid: true };
    }
  }

  //Mat Snack Bar
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'bottom', // 'top' | 'bottom'
      horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
    })
  }
  openSnackBar1(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['red-snackbar'],
      duration: 5000,
      verticalPosition: 'bottom', // 'top' | 'bottom'
      horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
    })
  }

  changePatientPwdSubmit() {
   
   
      this.isLoading = true;
      this.changePatientPasswordForm.get('mobileNumber').setValue(this.id)
      //console.log(this.signObj.access_token);
      console.log("adminResetPwd Req Data: ", this.changePatientPasswordForm.value);

      let payLoad = this.changePatientPasswordForm.value;
      delete payLoad.newPassword;
      console.log("req data : ", payLoad);

      this.common.resetUser(payLoad).subscribe(
        (result: { response: number; message: string; }) => {
          console.log("admin reset Pwd Response : ", result);

          if (result.response === 3) {
            //this.changePatientPasswordForm.reset();
            this.isLoading = false;
            this.successResponse = result.message;
           
            this.modalService.open(this.modalSuccessExample)
            this.router.navigateByUrl('/home');
           
            //this.openSnackBar(changePwdRes.message, "");
          }
          else {
            this.isLoading = false;
            this.failureResponse = result.message;
            this.modalService.open(this.modalFailureExample)
            //this.openSnackBar(changePwdRes.message, "");
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //this.loading = false;
            this.isLoading = false;
            console.log("Client Side Error", err);

          } 
        }
      );
     
  }

  viewSuccessMethod(viewSuccessContent: any) {
    this.modalService.open(viewSuccessContent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "sm" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  viewFailureMethod(viewFailureContent: any) {
    this.modalService.open(viewFailureContent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "sm" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showPassword1() {
    if (this.isPasswordOne === true) {
      this.password1 = "text";
      this.isPasswordOne = false;
    } else {
      this.password1 = "password"
      this.isPasswordOne = true;
    }
  }

  showPassword2() {
    if (this.isPasswordTwo === true) {
      this.password2 = "text";
      this.isPasswordTwo = false;
    } else {
      this.password2 = "password"
      this.isPasswordTwo = true;
    }
  }

}
