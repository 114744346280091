import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { commonService } from 'src/app/common.service';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from 'src/app/payment.service';
import { HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { ResourceLoader } from '@angular/compiler';
import { Router } from '@angular/router';





@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  //hosturl = 'https://api.Sree Kasi Visweswara Swamy Alaya Trust .org '
  hosturl = 'http://3.227.139.47:3000';
  closeResult: string;
  imagePopup: number;
  isLogin: boolean = false;
  isLoading: boolean = false;
  galleryForm: FormGroup;
  categoryName = 'education';
  imageUrl: any = "";
  filesArray: Array<any> = [];
  imgUrls: Array<any> = [];
  fileToUpload: File = null;
  galleryImages: Array<any> = [];
  allUser: Array<any> = [];
  isImgNotSelected: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private modalService: NgbModal, private commonService: commonService, private _snackBar: MatSnackBar, private common: PaymentService) { }

  ngOnInit() {
    this.isLogin = this.commonService.isLogin
    this.galleryForm = this.fb.group({
      categoryName: ["Education", Validators.required],
    })
    this.getGallery()
  }

  getGallery() {

    this.isLoading = true;
    this.common.getGallery().subscribe((gallery) => {
      console.log("login res", gallery);
      if (gallery.response === 3) {
        this.isLoading = false;
        gallery.result.map((img) => {
          img.images.map((images) => {
            this.galleryImages.push({ ...images, "categoryName": img.categoryName })

          })
        })
        console.log("Gallery Images", this.galleryImages)

        // this.hello();    
      } else {

        this.isLoading = false;
        this.openSnackBar(gallery.message, "");
      }



    }, (err: HttpErrorResponse) => {

      this.isLoading = false;
      if (err.error instanceof Error) {
        console.log("Client Error ::", err.error)
      } else {
        console.log("Server Error ::", err.error)
      }
    })

  }





  openTerms(content) {
    //   this.isButton = false;
    
    this.modalService.open(content, { centered: true, size: 'lg', backdrop: "static" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  openTerm(forgot, i) {
    this.imagePopup = i;
    //   this.isButton = false;
    this.modalService.open(forgot, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true, size: 'lg', backdrop: "static"
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }





  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //   setCatagery(value){
  //  this.imageCatagery = value
  //   }

  //   handleFileInput(files: FileList) {
  //     this.fileToUpload = files.item(0);
  // }

  handleFile(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  handle(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  handleFileOutput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  handleFileInput(event: any) {
    this.filesArray = [];
    for (let file of event.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = () => {
        //console.log(e)
        this.imageUrl = reader.result;
        this.imgUrls.push(reader.result);
        this.filesArray.push(file);
        this.isImgNotSelected = true;
        console.log("hyt", this.filesArray);
      }
    }
  }
  // handleFileInput(event :any){
  //   if(this.imgUrls.length < 5){
  //     let file =event.target.files[0];
  //   var reader = new FileReader();
  //   reader.readAsDataURL(file)
  //   reader.onload = () =>{
  //     this.imageUrl = reader.result;
  //     this.imgUrls.push(reader.result);
  //     this.filesArray.push(file);
  //     console.log("hyt" ,this.imgUrls);
  //   }
  //   }else{
  //    this.openSnackBar("only 5 images allowed","");
  //   }
  // }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['theme-snackbar'],
      duration: 2000,
      verticalPosition: "bottom", // 'top' | 'bottom'
      horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  openSnackBar1(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['red-snackbar'],
      duration: 2000,
      verticalPosition: "bottom", // 'top' | 'bottom'
      horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
    });
  }


  gallerySubmit() {
    //console.log(this.filesArray)
    if (!this.filesArray.length) {
      this.isImgNotSelected = true;
      return

    }
    if (this.galleryForm.valid) {
      //console.log("chech", this.galleryForm);
      this.isLoading = false;
      let formData = new FormData();
      formData.append("registerData", JSON.stringify(this.galleryForm.value));
      if (this.filesArray.length >= 1) {
        for (let i: number = 0; i < this.filesArray.length; i++) {
          if (i == 0) {
            formData.append("imgFile", this.filesArray[i]);
          } else {
            formData.append("imgFile" + i, this.filesArray[i])
          }
        }
        this.common.addImages(formData).subscribe((result) => {
          if (result.response == 3) {
            console.log("vamsi", result)
            this.modalService.dismissAll();
            

          } 
        })
      }
    }
  }

  cancelSubmit() {
    this.galleryForm.reset();
    this.imgUrls = [];
    this.modalService.dismissAll();
  }


  deletingGallery(i) {
    this.isLoading = true;
    let deleteGal = {
      "categoryName": this.galleryImages[i].categoryName,
      "imgPath": this.galleryImages[i].imgPath,
      "imgID": this.galleryImages[i].imgID,
    }
    console.log("beforeDelete", deleteGal);

    this.common.deleteGallery(deleteGal).subscribe((dele) => {

      console.log("del", dele);
      if (dele.response === 3) {
        this.isLoading = false;



        this.galleryImages.splice(i, 1);





        this.openSnackBar1(dele.message, "");

      } else {
        this.isLoading = false;

      }
    }
      , (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client Error ::", err.error)
        } else {
          console.log("Server Error ::", err.error)
        }
      })

  }

  leftButton() {
    console.log(this.imagePopup)
    if (this.imagePopup > 0) {
      this.imagePopup -= 1
    }
  }

  rightButton() {
    console.log(this.imagePopup)
    if (this.imagePopup < this.galleryImages.length) {
      this.imagePopup += 1
    }
  }


  deleteImage(index) {
    this.imgUrls.splice(index, 1);
    this.isImgNotSelected = false;
    this.filesArray.splice(index, 1);
  }
}