import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { PaymentService } from 'src/app/payment.service';
import { HttpBackend, HttpErrorResponse} from '@angular/common/http';
import { commonService } from 'src/app/common.service';
import { MatSnackBar } from '@angular/material';




export interface Section {
  name: string;
  updated: Date;


}
@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.css']
})
export class JoinUsComponent implements OnInit {
  MemeberList:[
    {"FileName":any},
    
 ];
 isLogin:boolean = false;
  closeResult:string;
  documentForm:FormGroup;
  isLoading: boolean = false;
  imgUrls:Array<any> = [];
  showMe:boolean=false;
  firstHalf:Array<any> = [];
  documentsArray:Array<any>=[];
  documentUrl:Array<any>=[];
  isImgNotSelected:boolean = false;
  hosturl = ' http://3.227.139.47:3000';

// folders: Section[] = [
//     {
//       name: 'Photos',
//       updated: new Date('1/1/16'),
//     },
//     {
//       name: 'Recipes',
//       updated: new Date('1/17/16'),
//     },
//     {
//       name: 'Work',
//       updated: new Date('1/28/16'),
//     }
//   ];
//   notes: Section[] = [
//     {
//       name: 'Vacation Itinerary',
//       updated: new Date('2/20/16'),
//     },
//     {
//       name: 'Kitchen Remodel',
//       updated: new Date('1/18/16'),
//     }
//   ];

  constructor(private modalService: NgbModal,private fb:FormBuilder,private common:PaymentService, private commonService:commonService,private _snackBar:MatSnackBar) { }

  ngOnInit() {
   this.isLogin = this.commonService.isLogin
    this.documentForm=this.fb.group({
      categoryName:["Trust Documents",Validators.required],
      FileName:["",Validators.required]
    })

    this.getDocumentsData();

  }

  openTurn(reset) {
       
    //   this.isButton = false;
       this.modalService.open(reset, { ariaLabelledBy: 'modal-basic-title',centered:true, size: 'lg', backdrop:"static" }).result.then((result) => {
         this.closeResult = `Closed with: ${result}`;
       }, (reason) => {
         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       });
     }

     private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }

   


    documentsSubmit(){
      if(!this.documentUrl.length){
        this.isImgNotSelected = true;
        return
      }


      if(this.documentForm.valid && this.documentUrl && !this.isImgNotSelected){
         

        console.log("chech",this.documentForm);
       
        this.isLoading = true; 
         let formData = new FormData();
         formData.append("registerData",JSON.stringify(this.documentForm.value));
         if(this.documentUrl.length >= 1){
          for(let i:number = 0; i< this.documentUrl.length; i++){
           if(i == 0){
             formData.append("imgFile",this.documentUrl[i]);
           }else{
             formData.append("imgFile"+i, this.documentUrl[i])
           }
          }
        }
        this.common.uploadGallery(formData).subscribe((login)=>
        {
    
          console.log("login res",login);
    
        
          if(login.response === 3){
            this.isLoading = false; 
            this.modalService.dismissAll();
            this.openSnackBar(login.message, "");
            
          
            
            
            this.getDocumentsData()
    
          }else{
            this.isLoading = false; 
          }   
         
        }
         ,(err:HttpErrorResponse)=>{
          this.isLoading = false; 
          if(err.error instanceof Error){
            console.log("Client Error ::",err.error)
          }else{
            console.log("Server Error ::",err.error) 
          }
        })
      
      }else{
       // alert("Form is not valid");
      }
    }

    getDocumentsData(){
      this.common.getDocuments().subscribe((getdata)=>
      {
        if(getdata.response ===3){
          this.documentsArray= getdata.result
          console.log("geting",getdata)
          this.documentForm.reset();
        }
      })
    }


    toggleTag(i){
      if(this.documentsArray[i].documents.length){
        this.showMe=!this.showMe
      console.log(document.getElementById("show"+i).style.display)
      if(document.getElementById("show"+i).style.display=="block"){
        document.getElementById("show"+i).style.display="none"
      }else{
        document.getElementById("show"+i).style.display="block"
      }
      }else{
         
      }
      
    }


    handleFileInput(event :any){
      
        let file =event[0];
        console.log(file , !['docx','pdf'].includes(file.name.split('.')[1]))
        if(file &&file.name&&!['docx','pdf'].includes(file.name.split('.')[1])){
          file = null;
          this.isImgNotSelected = true;
          return
        } else {
          if(file.size <= 20971520){
            var reader = new FileReader();
          reader.readAsDataURL(file)
          console.log(file);
         
          reader.onload = () =>{
           
           
            this.documentUrl.push(file);
            this.isImgNotSelected=false;
            console.log("hyt" ,this.imgUrls);
          }
          }else{
            this.openSnackBar("allow only 20mb","")
          }
        }
     
    }


    cancelSubmit(){
      this.documentForm.reset();
      this.documentUrl=[];
      this.modalService.dismissAll();
     
    }

    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
        panelClass: ['theme-snackbar'],
        duration: 2000,
        verticalPosition: "bottom", // 'top' | 'bottom'
        horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
      });
    }

    openSnackBar1(message: string, action: string) {
      this._snackBar.open(message, action, {
        panelClass: ['red-snackbar'],
        duration: 2000,
        verticalPosition: "bottom", // 'top' | 'bottom'
        horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
      });
    }

    deleteApi(i,del,j){
      let  deleteObj ={
        "categoryName": this.documentsArray[i].categoryName,
        "docID":del.docID,
        "docPath":del.docPath
        }

        this.isLoading=true;
      this.common.deleteDocuments(deleteObj).subscribe((res)=>
      {
           console.log(res)
           if(res.response===3){
             this.isLoading=false;
             this.documentsArray[i].documents.splice(j,1);
             this.openSnackBar1(res.message,"");
     
           }else{
             this.isLoading=false;
           }
      })
    }


}
