import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  constructor(config: NgbCarouselConfig) {
    config.interval = 30000;
   }

  ngOnInit() {
  }
  images=[
"assets/caurosel/2.jpeg","assets/caurosel/3.jpeg","assets/caurosel/4.jpeg","assets/caurosel/5.jpeg",
"assets/caurosel/7.jpeg","assets/caurosel/8.jpeg","assets/Temple1/2.jpeg","assets/Temple1/3.jpeg","assets/Temple1/4.jpeg",
"assets/Temple1/7.jpeg","assets/Temple1/8.jpeg","assets/Temple1/9.jpeg","assets/Temple1/10.jpeg",
"assets/Temple2/2.jpeg",
"assets/Temple2/7.jpeg","assets/Temple2/8.jpeg","assets/Temple2/10.jpeg","assets/Temple2/13.jpeg"
]
 // images = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

}
