import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PaymentService } from '../payment.service';
import { MatSnackBar } from '@angular/material';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../components/dialog/dialog.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sugam',
  templateUrl: './sugam.component.html',
  styleUrls: ['./sugam.component.css']
})
export class SugamComponent implements OnInit {
  sugamform: FormGroup
  submitted: boolean;
  loading: boolean;
  productsSubcribe: any;
  serverResponse: any;
  productsList: any = [];
  isLoading: boolean;
  sub: any;
  pujaName: any;
  issubmitted:boolean=true;
  isupdated:boolean=false;
 today:string="";
 maxDate:string="";
 tomorrow = new Date();
  yesterday= new Date();
  
  constructor(private formBuilder: FormBuilder, private services: PaymentService, private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute, private dialog:MatDialog,private router:Router) {

      this.tomorrow.setDate(this.tomorrow.getDate() + 0);
      this.yesterday.setDate(this.yesterday.getDate() - 0);
    this.getdata()
  }


  ngOnInit() {
    let yy= new Date().getFullYear();
    let mm = new Date().getMonth()+1;
    let dd= new Date().getDate();
    
    this.today=yy+"-"+mm+"-"+dd+"T00:00:00";
    this.maxDate=yy+"-"+mm+"-"+dd+"T00:59:59";

    this.sugamform = this.formBuilder.group(
      {
        pujaName: new FormControl('', [Validators.required,]),
        StartTime: new FormControl('', [Validators.required,]),
        EndTime: new FormControl('', [Validators.required,]),
        stHour:new FormControl('', [Validators.required]),
        Stminits:new FormControl('', [Validators.required,]),
        endHour:new FormControl('', [Validators.required,]),
        endMintues:new FormControl('', [Validators.required,]),
        
      })

   
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.pujaName = params['pujaName'];
      this.getdata();
     
    })
    
  }


  getdata() {
   
    this.productsSubcribe = this.services.getProduct().subscribe((posRes: any) => {
      this.serverResponse = posRes;
      console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        this.productsList = this.serverResponse.Info
      }
    })
   }

   twodigit(event){
let patt = /^\d{1}$|^\d{2}$/;
let result = patt.test(event.key);
return result
   }

  onSubmit() {
    this.submitted = true;
    //console.log(this.sugamform.value);
    console.log(this.sugamform.get("StartTime").value);

    if(this.issubmitted){
      if (this.submitted) {

        // let stDate = this.sugamform.get("StartTime").value;
        // let etDate =  this.sugamform.get("EndTime").value;
        // console.log(stDate);
        // console.log(etDate);
        // let sstsdate=this.onTimeChange(stDate);
        // let eddate = this.onTimeChange(etDate);
        
        // console.log(sstsdate);
        // console.log(eddate);
        // // var st1Date = new Date(stDate).getTime();
        // // var et1Date = new Date(etDate).getTime();
        var stf = this.sugamform.get("Stminits").value;
        var sth = this.sugamform.get("stHour").value;
        if(Number(stf)<10){
          if(stf[0] != "0"){
            stf = "0"+stf;
          }
        }
        if(Number(sth)<10){
          if(sth[0] != "0"){
            sth = "0"+sth;
          }
        }
        var etf = this.sugamform.get("endMintues").value;
        if(Number(etf)<10){
          if(etf[0] != "0"){
            etf = "0"+etf;
          }
        }

        var eth = this.sugamform.get("endHour").value;
        if(Number(eth)<10){
          if(eth[0] != "0"){
            eth = "0"+eth;
          }
        }
        let payload = {
          pujaName: this.sugamform.get("pujaName").value,
          StartTime :""+sth+":"+stf+" "+this.sugamform.get("StartTime").value,
          EndTime : ""+eth+":"+etf+" "+this.sugamform.get("EndTime").value
        }
        console.log(payload);
        this.services.sugam(payload).subscribe((result: { response: number; message: string; }) => {
          console.log(result);
          if(result.response==3){
            this.sugamform.reset();
            this.getdata();
            this.openSnackBar(result.message,"");
          }else{
            this.openSnackBar(result.message,"");
          }
        })
      }(err: HttpErrorResponse) => {
        this.openSnackBar(err.message, "");
        if (err.error instanceof Error) {
          console.warn("Client Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      }
    }else{
      if (this.submitted) {
        var stf = this.sugamform.get("Stminits").value;
        if(Number(stf)<10){
          if(stf[0] != "0")
          stf = "0"+stf;
        }
        var etf = this.sugamform.get("endMintues").value;
        if(Number(etf)<10){
          if(etf[0] !="0")
          etf = "0"+etf;
        }
        let payload = {
          pujaName: this.sugamform.get("pujaName").value,
          StartTime :""+this.sugamform.get("stHour").value+":"+stf+" "+this.sugamform.get("StartTime").value,
          EndTime : ""+this.sugamform.get("endHour").value+":"+etf+" "+this.sugamform.get("EndTime").value
        }
        console.log(payload);
        this.services.updatepuja(payload).subscribe((result) => {
          console.log(result);
          if(result.response==3){
            this.sugamform.reset();
            this.issubmitted=true;
            this.isupdated=false;
            this.getdata();
            this.openSnackBar(result.message,"");
  
          }
        })
      }(err: HttpErrorResponse) => {
        this.openSnackBar(err.message, "");
        if (err.error instanceof Error) {
          console.warn("Client Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      }

    }
    
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }


   formatAMPM(date1: string | number | Date) {
    var date = new Date(date1);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var hour = hours< 10 ? '0'+hours : ''+hours;
    var minutes1 = minutes < 10 ? '0'+minutes : ''+minutes;
    var strTime = hour + ':' + minutes1 + ' ' + ampm;
    return strTime;
  }
  
  

  deletingsugam(user: { pujaName: any; }) {

      const dialogRef = this.dialog.open(DialogComponent, {
        height: '200px',
        width: '250px',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == true) {
          console.log(user)
          this.isLoading = true;
          let obj = {
            pujaName:user.pujaName
          }
          
          this.services.deletepuja(obj).subscribe((dele: { response: number; message: string; }) => {
      
            console.log("del", dele);
            if (dele.response === 3) {
              this.isLoading = false;
              this.openSnackBar(dele.message, "");
             this.getdata()
            } else {
              this.isLoading = false;
            }
          }
            , (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                console.log("Client Error ::", err.error)
              } else {
                console.log("Server Error ::", err.error)
              }
            })
           
        }
        else{
          this.getdata()

        }
      })

   

  }

  editsugam(user: any){
    console.log(user)
    //let st = user.StartTime.toTimeString()
    var sttime= user.StartTime.split(':');
    var etTime = user.EndTime.split(':');
    var tt = sttime[1];
    var stt = tt.substr(0,tt.indexOf(' '));
    // if(Number(stt)<10){
    //   stt="0"+stt;
    // }
    var stAm = tt.substr(tt.indexOf(' ')+1);

    var tt1 = etTime[1];
    var ett = tt1.substr(0,tt.indexOf(' '));
    // if(Number(ett)<10){
    //   ett="0"+ett;
    // }
    var etAm = tt1.substr(tt.indexOf(' ')+1);
    this.sugamform.patchValue({
      pujaName:user.pujaName,
      stHour:sttime[0],
      endHour:etTime[0],
      Stminits : stt,
      endMintues : ett,
      StartTime : stAm,
      EndTime : etAm
    })
    this.issubmitted=false;
    this.isupdated=true;
    document.getElementById("pname").focus();
  }
  onTimeChange(stDate) {

    //let stDate = this.sugamform.get("StartTime").value;
    var timeSplit = stDate.split(':'),
     hours: number,
      minutes: string,
      meridian: string;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = 'PM';
      hours -= 12;
    } else if (hours < 12) {
      meridian = 'AM';
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = 'PM';
    }
    return hours + ':' + minutes + ' ' + meridian;
  }

  
  


}
