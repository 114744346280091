import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../payment.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';





@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.css']
})
export class ApproveComponent implements OnInit {

  // urlLink="https://api.Sree Kasi Visweswara Swamy Alaya Trust .org ";
  urlLink = "https://liveapi-hariharakshethra.softexer.com";

  isLoading: boolean = false;
  allUser: any = [];
  closeResult: string;
  //imagePopup:string;
  imagePopup: number;
  galleryImages: Array<any> = [];
  imagepopupNew: number;


  constructor(private common: PaymentService, private modalService: NgbModal, private router: Router, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.offlineTransaction();
  }

  offlineTransaction() {
    this.isLoading = true;
    let hai = [];
    this.common.offline(hai).subscribe((off) => {
      console.log("hello", off);
      if (off.response === 3) {
        this.isLoading = false;
        this.allUser = off.results;
      } else {
        this.isLoading = false;
      }
    })
  }


  openTerms(content, i, j) {
    //   this.isButton = false;
    this.imagePopup = j;
    this.imagepopupNew = i;
    console.log(this.allUser[this.imagepopupNew].offlineImages[this.imagePopup])
    this.modalService.open(content, { centered: true, size: 'lg', backdrop: "static" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  approve(data, i) {
    console.log("check", data);
    this.isLoading = true;
    let payment = {
      "paymentID": data.paymentID,
      "statusForPayment": "Aprove"

    }

    this.common.approveApi(payment).subscribe((pay) => {
      console.log("paymee", pay);

      if (pay.response === 3) {
        this.isLoading = false;
        this.offlineTransaction();
        this.openSnackBar(pay.message, "");
        this.router.navigateByUrl('/transactions')
      } else {
        this.isLoading = false;

      }
    }
      , (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client Error ::", err.error)
        } else {
          console.log("Server Error ::", err.error)
        }
      }
    )


  }

  reject(data, i) {
    this.isLoading = true;
    let rejectPay = {
      "paymentID": data.paymentID,
      "statusForPayment": "Reject"

    }
    this.common.approveApi(rejectPay).subscribe((rej) => {
      console.log("rejee", rej);

      if (rej.response === 3) {
        this.isLoading = false;
        this.offlineTransaction();
        this.openSnackBar1(rej.message, "");
      } else {
        this.isLoading = false;
      }
    }
      , (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client Error ::", err.error)
        } else {
          console.log("Server Error ::", err.error)
        }
      }

    )

  }


  delete(data, i) {
    this.isLoading = true;
    let deletePay = {
      "paymentID": data.paymentID,
      "statusForPayment": "Delete"

    }
    this.common.approveApi(deletePay).subscribe((dele) => {
      console.log("del", dele);
      if (dele.response === 3) {
        this.isLoading = false;
        this.offlineTransaction();
        this.allUser.splice(i, 1);
        this.openSnackBar1(dele.message, "");

      } else {
        this.isLoading = false;

      }
    }
      , (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client Error ::", err.error)
        } else {
          console.log("Server Error ::", err.error)
        }
      })

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['theme-snackbar'],
      duration: 1000,
      verticalPosition: "bottom", // 'top' | 'bottom'
      horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  openSnackBar1(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['red-snackbar'],
      duration: 1000,
      verticalPosition: "bottom", // 'top' | 'bottom'
      horizontalPosition: "right", //'start' | 'center' | 'end' | 'left' | 'right'
    });
  }


  leftButton() {
    console.log(this.imagePopup)
    if (this.imagePopup > 0) {
      this.imagePopup -= 1
    }
  }

  rightButton() {
    console.log(this.imagePopup)
    if (this.imagePopup < this.allUser[this.imagepopupNew].offlineImages.length - 1) {
      this.imagePopup += 1
    }
  }

}
