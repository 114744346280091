import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from '../payment.service'; 
import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog, MatSnackBar } from "@angular/material";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  windowRef: any;
  phone: any;
  // phoneNumber = new PhoneNumber();
  verificationCode: string;
  user: any;
  otpResponse: any;
  submitted: any;
  loading: boolean;
  constructor(
    private router: Router,
    private services: PaymentService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group(
      {
        mobileNumber: new FormControl('', [Validators.required,]),
        password: new FormControl('',[Validators.required]),
        deviceToken:"1234",
        deviceID:"web",
        deviceType:"web"
      });
  }

    //message alerts showing
    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 3000,
        panelClass: "red-snackbar",
      });
    }

  onSubmit() {
    this.submitted = true;
    console.log("successfully login")
    if (this.submitted) {
      this.loading = true;

      console.log(this.loginForm.value)
      console.log(JSON.stringify(this.loginForm.value, null, 2));
      this.services.loginskvt(this.loginForm.value).subscribe((result) => {
        console.log(result);
        let userinfo = JSON.stringify(result);
        let uinfo = JSON.parse(userinfo);
        if(uinfo.response == 3){
          this.services.iscustomerLoggedIn.next(true);
          localStorage.setItem('user', JSON.stringify(uinfo.Info));
          localStorage.setItem("token", uinfo.jsontoken);
          this.router.navigateByUrl('/home')
        }else{
          this.openSnackBar(uinfo.message,"")
        }
        
        // if (result.response == 3) {
        //   console.log(result);
        //   this.router.navigateByUrl('/home')

        // }

      },
      (err: HttpErrorResponse) => {
        this.openSnackBar(err.message, "");
        if (err.error instanceof Error) {
          console.warn("Client Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })
    } else {
      this.router.navigateByUrl('/timeSlots')
    }
  }


}
