import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { PaymentService } from 'src/app/payment.service';
import { MethodCall } from '@angular/compiler';
import { MatDialog } from '@angular/material';
import { PaymentComponent } from '../payment/payment.component';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-billdeskpay',
  templateUrl: './billdeskpay.component.html',
  styleUrls: ['./billdeskpay.component.css']
})
export class BilldeskpayComponent implements OnInit {
  donationForm:FormGroup;
  loading:boolean = false;
  constructor(private fb:FormBuilder,private service:PaymentService, private dialog:MatDialog) { }

  ngOnInit() {
    this.donationForm = this.fb.group({
      amount:["",[Validators.required,Validators.pattern('[0-9]*')]]
    })
  }

  donate(){
    if(this.donationForm.valid){
      this.loading = true;
      let payLod = {
        amount : this.donationForm.get("amount").value,
        currency :"356"
      }
      this.service.getBilldesktoken(payLod).subscribe((result)=>{
        var dataInfo = result.Tokenval;
          var flow_config = {
            merchantId: dataInfo.mercid,
            bdOrderId: dataInfo.bdorderid,
            authToken:dataInfo.links[1].headers.authorization,
            childWindow: false,
            returnUrl: dataInfo.ru,
			      retryCount: 0
          };
        var config = {
            flowConfig: flow_config,
            flowType: "payments"
        };
        document.querySelector(".payment").innerHTML
      })
    }else{
      alert("Enter all required fields..")
    }

  }

}
