
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { HttpErrorResponse,HttpClient } from '@angular/common/http';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  previewUrl: any;
  fileUploadProgress: any;
  uploadedFilePath: any;
  loading: boolean = false;
  confirmPassword: boolean = false;
  showProfileForm: boolean = false;
  showLoginForm: boolean = true;
  signupForm: FormGroup | any;
  submitted = 'false';
  hide = true;
  hide2 = true;
//   myForm = new FormGroup({
//    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
//    file: new FormControl('', [Validators.required]),
//    fileSource: new FormControl('', [Validators.required])
//  });
 
  form: FormGroup = new FormGroup({
    Password: new FormControl(''),
    confirmPassword: new FormControl('')
  });
  fileData: any;
  SignupComponent: any;

  constructor(private router: Router,
    private fb: FormBuilder,
    private loginservice: PaymentService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {  }

  ngOnInit(): void {
    this.signupForm = this.fb.group({
      image: ["",Validators.required],
      fullName: ["",Validators.required],
      mobileNumber: ["",Validators.required],
      emailID: new FormControl('', [Validators.required,
      Validators.email, Validators.pattern(
        '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$')
      ]),
      password: new FormControl(
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$')

        ]
      )
    }
    )
    //this.previewUrl = "https://qa-api.startasker.com/user.png";
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.signupForm.get("image").setValue(this.fileData);
    console.log("image success");
    this.preview();
  }

  preview() {
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      console.log(this.previewUrl);
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

  onSubmit() {

    if (this.signupForm.invalid) {
      return
    }

    if (this.submitted) {
      let payLoad = { ...this.signupForm.value };
      delete payLoad.confirmPassword;
      delete payLoad.image;
      let formdata= new FormData();
      formdata.append("profilePic",this.signupForm.get("image").value);
      formdata.append("registerData",JSON.stringify(payLoad));
      console.log(payLoad);
      this.loginservice.signup(formdata).subscribe(
        (result) => {
          console.log(result);
          if (result.response == 3) {
            this.loading = false;
            console.log(result.stuinfo);
            this.openSnackBar(result.message, "");
            localStorage.setItem("user", JSON.stringify(result.stuinfo));
            localStorage.setItem("token", result.token);
            this.router.navigateByUrl('/home')
          }
          else {
            this.loading = false;
            this.openSnackBar(result.message, "");
            this.showProfileForm = false;
          }
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          if (err.error instanceof Error) {
            this.openSnackBar(err.message, "Client Error");
          } else {
            this.openSnackBar(err.message, "Server Error");
          }
        }
      )
    }
  }
  get f() { return this.signupForm.controls; }
}

// export default class Validation {
//   static match(controlName: string, checkControlName: string): ValidatorFn {
//     return (controls: AbstractControl) => {
//       const control = controls.get(controlName);
//       const checkControl = controls.get(checkControlName);
//       if (checkControl?.errors && !checkControl.errors['matching']) {
//         return null;
//       }
//       if (control?.value !== checkControl?.value) {
//         controls.get(checkControlName)?.setErrors({ matching: true });
//         return { matching: true };
//       } else {
//         return null;
//       }
//     };
//   }
// }